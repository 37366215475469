(function() {
    this.sa = function() {
        var _this = this;
        this.mobile = false;
        this.html5 = true;
        this.players = [];
        this.videos = [];
        this.errors = [];
        this.body = document.getElementsByTagName("BODY")[0];
        // to use, set your new variable equal to sa.requestAnimationFrame.bind(window)
        this.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        this.animationPrefixes = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd animationend webkitAnimationEnd';
        // https://css-tricks.com/snippets/javascript/javascript-keycodes/
        this.colors = {
            accent: '#0082ff',
            accentHighlight: '#4da8ff',
            accentLight: '#e6f3ff',
            accentDark: '#005bb3',
            background: '#ffffff',
            secondaryBackground: '#f7f7f7',
            offWhite: '#eeeeee',
            lightestGray: '#dfdfdf',
            lightGray: '#aaaaaa',
            gray: '#888888',
            darkGray: '#6e6e6e',
            darkestGray: '#333333',
            black: '#222222',
            trueBlack: '#000000',
        };
        this.keycodes = {
            backspace: 8,
            tab: 9,
            enter: 13,
            // shift: 16,
            // ctrl: 17,
            // alt: 18,
            esc: 27,
            space: 32,
            arrowLeft: 37,
            arrowUp: 38,
            arrowRight: 39,
            arrowDown: 40,
            delete: 46,
            a: 65,
            f: 70,
            j: 74,
            k: 75,
            l: 76,
            m: 77,
            p: 80,
            v: 86,
            colon: 186, // requires shift
            semicolon: 186,
            plus: 187, // requires shift
            comma: 188,
            minus: 189,
            hyphen: 189, // requires shift
            period: 190,
            questionMark: 191, // requires shift
            shift: function(event){
                return event.shiftKey;
            },
            ctrl: function(){
                // metaKey is cmd on mac, nothing on pc
                // ctrlKey is ctrl on pc and control on mac
                // it's ok to have both cmd and control function the same way in this case
                return event.ctrlKey || event.metaKey;
            },
            modifier: function(event){
                var modifier = (event.metaKey || event.shiftKey || event.altKey) ? true : false;
                return modifier;
            },
        };
        this.init = function () {
            // USE THIS TO TEST CLICKED ELEMENTS
            // $('*').on('click', function(event){
            //     event.stopImmediatePropagation();
            //     console.log($(this));
            // });

            _this.vue.setup()

            window.addEventListener('orientationchange', function() {
                $(window).trigger('resize');
                setTimeout(function(){
                    $(window).trigger('resize');
                }, 500);
            }, false);

            Array.prototype.diff = function(a) {
                return this.filter(function(i) {return a.indexOf(i) < 0;});
            };
            String.prototype.in = function(s) {
                return s.indexOf(this) != -1;
            };
            String.prototype.sanitize = function(){
                return $('<div></div>').html(this).text();
            };
            Array.prototype.in = function(s) {
                for(var i = 0; i < this.length; i++){
                    if(this[i].in(s)) return true;
                }
                return false;
            };
            Array.prototype.is = function(s) {
                for(var i = 0; i < this.length; i++){
                    if(this[i] == s) return true;
                }
                return false;
            };
            Array.prototype.sum = function(){
                return this.reduce(function(a, b){ return a + b; }, 0);
            }
            Array.prototype.sortDescending = function(){
                return this.sort(function(a, b){return b-a});
            }
            Number.prototype.clamp = function(min, max) {
                return Math.min(Math.max(this, min), max);
            };
            Array.prototype.clean = function(){
                return this.filter(function(e){ return e === 0 || e });
            };
            Array.prototype.stripUndefined = function(){
                return this.filter(function(e){ return e !== undefined });
            };
            Date.prototype.clone = function(){
                return new Date(this.getTime());
            };
            (function( $ ) {
                // TODO: maybe change this to an object instead of positional arguments
                $.fn.keysEvent = function(keys, e, func, dontPreventDefault, preventPropogation) {
                    this.on(e, function(event){
                        for(var i = 0; i < keys.length; i++){
                            if (event.keyCode == keys[i]) {
                                if(!dontPreventDefault) event.preventDefault();
                                if(preventPropogation) event.stopPropagation();
                                func.call(this, event);
                                break;
                            }
                        }
                    });
                    return this;
                };
                $.fn.toggleDisability = function(disabledValue){
                    var isDisabled;
                    if(typeof disabledValue == undefined){
                        isDisabled = $(this).hasClass('disabled') || $(this).prop('disabled');
                    } else {
                        isDisabled = disabledValue;
                    }

                    if(isDisabled){
                        $(this).enable();
                    } else {
                        $(this).disable();
                    }
                    return this;
                };
                $.fn.disable = function(){
                    var useProp = $(this).is('input, button, textarea');
                    if(useProp){
                        $(this).prop('disabled', true);
                    } else {
                        $(this).addClass('disabled');
                        if($(this).hasClass('pseudo-input')){
                            $(this).find('input, button, textarea').prop('disabled', true);
                        }
                    }
                    return this;
                };
                $.fn.enable = function(){
                    var useProp = $(this).is('input, button, textarea');
                    if(useProp){
                        $(this).prop('disabled', false);
                    } else {
                        $(this).removeClass('disabled');
                        if(!$(this).hasClass('pseudo-input')) return;
                        $(this).find('input, button, textarea').prop('disabled', false);
                    }
                    return this;
                };
                $.fn.saCheckbox = function(func) {
                    var box = $(this);
                    var label = $('#' + box.attr('aria-labelledby'));
                    label.on('click', function(){
                        box.attr('aria-checked', !box.prop('checked'));
                        label.attr('aria-checked', !box.prop('checked'));
                    }).keysEvent([_this.keycodes.space, _this.keycodes.enter], 'keypress', function(){
                        box.prop('checked', !box.prop('checked'));
                        box.attr('aria-checked', box.prop('checked'));
                        label.attr('aria-checked', box.prop('checked'));
                        func.call(box, event);
                    });
                    box.on('click', function(event){
                        func.call(this, event);
                    });
                    return this;
                };
                $.fn.saClick = function(func, namespace, dontPreventDefault, skipSpace) {
                    var element = this;
                    namespace = namespace || '';
                    element.on('click' + namespace, function(event){
                        func.call(this, event, this);
                    });
                    var keyList = skipSpace ? [_this.keycodes.enter] : [_this.keycodes.space, _this.keycodes.enter];
                    element.keysEvent(keyList, 'keypress' + namespace, func, dontPreventDefault);
                    return this;
                };
                $.fn.outlineCallback = function(func){
                    _this.aria.outline.focusFunctions.push([this, func]);
                    return this;
                };
                $.fn.offClick = function(func){
                    _this.offClick.bind(this, func);
                    return this;
                };
                $.fn.getRealDimensions = function (includeMargin) {
                    var $item = this,
                    props = { position: 'absolute', visibility: 'hidden', display: 'block' },
                    dim = { width: 0, height: 0, innerWidth: 0, innerHeight: 0, outerWidth: 0, outerHeight: 0 },
                    $hiddenParents = $item.parents().addBack().not(':visible'),
                    includeMargin = (includeMargin == null) ? false : includeMargin;

                    var oldProps = [];
                    $hiddenParents.each(function () {
                        var old = {};
                        for (var name in props) {
                            old[name] = this.style[name];
                            this.style[name] = props[name];
                        }
                        oldProps.push(old);
                    });

                    dim.width = $item.width();
                    dim.outerWidth = $item.outerWidth(includeMargin);
                    dim.innerWidth = $item.innerWidth();
                    dim.height = $item.height();
                    dim.innerHeight = $item.innerHeight();
                    dim.outerHeight = $item.outerHeight(includeMargin);

                    $hiddenParents.each(function (i) {
                        var old = oldProps[i];
                        for (var name in props) {
                            this.style[name] = old[name];
                        }
                    });

                    return dim;
                };
            }( jQuery ));
            _this.checkBrowser();
            $(document).ready(function(){
                if(typeof Sentry !== 'undefined') _this.sentry.setup();
                _this.animationFrame = _this.requestAnimationFrame.bind(window);
                _this.setupSelectAll();
                _this.setupCopyText();
                _this.setupNumberInput();
                _this.aria.outline.setup();
                _this.webcasts.setupNotifications();
                _this.search.setup();
                _this.accordion.init();
                _this.customDropdown.setup();
                _this.popups.default();
                _this.checkScrollbars();
                _this.playerOverlays.setupToggles();
                _this.playerOverlays.setupWebcastNotifications();
                _this.lazyLoad();
                _this.i18n.selectorSetup();
                _this.qrCodes.setup();
                setTimeout(function(){
                    $('html').addClass('loaded');
                    $(document).trigger('sa');
                }, 0);
            });
        };
        this.vue = {
            host: '*',
            callbacks: [],
            addCallback(callback){
                _this.vue.callbacks.push(callback)
            },
            postMessage(iframe, event, message) {
                const w = iframe[0].contentWindow
                if (!w) return
                w.postMessage(
                    JSON.stringify({
                        event,
                        message,
                    }), _this.vue.host
                )
            },
            setup(){
                function getMessage(event){
                    try {
                        return JSON.parse(event.data)
                    } catch (e) {
                        return {
                            event: undefined,
                            message: '',
                        }
                    }
                }
                window.addEventListener('message', (e) => {
                    const message = getMessage(e)
                    if(message.event === 'toast-error'){
                        sa.toasts.toast(message.message, sa.toasts.types.error);
                    } else if (message.event === 'toast-success'){
                        sa.toasts.toast(message.message, sa.toasts.types.success);
                    } else if (message.event === 'navigate'){
                        window.location.href = message.message;
                    } else if (message.event === 'qs'){
                        let qs = message.message || ''
                        qs = qs.startsWith('?') ? qs : `?${qs}`
                        if(qs === '?') qs = ''
                        history.pushState({}, '', window.location.pathname + qs)
                    } else {
                        for(let i = 0; i < _this.vue.callbacks.length; i++){
                            _this.vue.callbacks[i](message)
                        }
                    }
                })
            }
        };
        this.lazyLoad = function(){
            var onload = function(element, url){
                element.attr('src', url).removeData('lazy-load').removeAttr('data-lazy-load');
                setTimeout(function(){
                    element.removeClass('lazy-load').addClass('lazy-loaded');
                }, 100);
            };
            $('[data-lazy-load]').each(function(){
                var img = new Image();
                var url = $(this).data('lazy-load');
                img.onload = onload($(this), url);
                img.scr = url;
            });
        };
        this.setupCSRF = function(){
            $.ajaxSetup({
                headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
            });
        };
        this.accordion = {
            init: function(){
                $(window).on('resize.accordion', _this.accordion.setHeights);
                _this.accordion.setup();
                _this.accordion.setHeights();
            },
            setHeights: function(){
                $('.accordion-button').each(function(){
                    var container = $(this).next('.accordion-container');
                    container.removeAttr('style');
                    container.css('height', container[0].scrollHeight);
                });
            },
            setup: function(){
                $('.pro-tip').saClick(function(event){
                    event.stopPropagation();
                    $(this).find('.accordion-button').trigger('click');
                });
                $('.accordion-button').not('.setup').saClick(function(event){
                    event.stopPropagation();
                    var button = $(this);
                    if(!button.hasClass('minimized')){
                        var container = $(this).next('.accordion-container');
                        var height = container[0].scrollHeight;
                        if(button.hasClass('closed')){
                            container.css('height', height);
                            setTimeout(function(){
                                button.removeClass('closed');
                                button.trigger('toggled');
                            }, 0);
                        } else {
                            button.addClass('closed');
                            button.trigger('toggled');
                        }
                    }
                });
                $('.accordion-button').addClass('setup').on('toggled', function(){
                    $(this).one(_this.animationPrefixes, function(){
                        $(window).trigger('resize');
                    });
                });
            },
        };
        this.i18n = {
            korean: 'ko',
            chinese: 'zh',
            portuguese: 'pt',
            spanish: 'es',
            german: 'de',
            english: 'en',
            flatpickrLocalized: false,
            flatpickrSetup: function(){
                if(typeof flatpickr === 'undefined' || _this.i18n.flatpickrLocalized) return;
                _this.i18n.flatpickrLocalized = true;
                var lang = _this.language.split('-')[0];
                lang = flatpickr.l10ns[lang];
                if(!lang) return;
                flatpickr.localize(lang);
            },
            setLanguage: function(language, reload){
                _this.urlHelpers.removeParametersFromUrl(['language']);
                var li = $('.translation-dropdown li[data-language="' + language + '"]');
                if(!li.length) return;
                var icon = li.find('.translation-icon span').first().text();
                $('.translation-dropdown-button > .translation-icon span').text(icon);
                Cookies.set('django_language', language);

                $('.translation-dropdown li:not(.report-error)').removeClass('active');
                li.addClass('active');

                if(!reload) return;
                location.reload();
            },
            selectorSetup: function(){
                var selectorButton = $('.translation-dropdown-button');
                if(!selectorButton[0]) return;
                selectorButton.each(function(){
                    var parent = $(this);
                    parent.saClick(function(){
                        parent.toggleClass('active').addClass('init');
                        if(parent.hasClass('active')){
                            parent.offClick(function(){
                                parent.removeClass('active');
                            });
                        }
                    });
                    var lis = parent.find('.translation-dropdown li:not(.report-error)');
                    lis.saClick(function(){
                        var language = $(this).data('language');
                        _this.i18n.setLanguage(language, true);
                    });

                    $('.translation-dropdown .report-error').saClick(function(){
                        _this.alertModal({
                            modal: '.report-mistranslation-modal',
                            confirmation_close: false,
                            default_close: true,
                        });
                    });
                    $('#mistranslation-form').on('submit', function(event){
                        event.preventDefault();
                        var moreInfo = $('#mistranslation-more-info').val();
                        var incorrectString = $('#mistranslation-current-text').val();
                        var correctedString = $('#mistranslation-suggested-text').val();

                        if(moreInfo == "" || correctedString == "" || incorrectString == "") return;
                        var submitButton = $('#mistranslation-submit');
                        submitButton.addClass('processing').disable();
                        var url = $('.translation-dropdown .report-error').data('report-url');
                        var data = {
                            'url': window.location.href,
                            'name': $('#mistranslation-name').val(),
                            'email': $('#mistranslation-email').val(),
                            'body': moreInfo,
                            'incorrectString': incorrectString,
                            'correctedString': correctedString,
                        };
                        $.ajax({
                            url: url,
                            type: 'POST',
                            contentType: 'application/json',
                            data: JSON.stringify(data),
                            success: function (data) {
                                sa.toasts.toast(gettext("Your feedback has been sent."), sa.toasts.types.success);
                                submitButton.removeClass('processing').data('original-text', submitButton.text()).text(gettext("Thanks!"));
                                setTimeout(function(){
                                    $('.report-mistranslation-modal').trigger('close');
                                }, 500);
                            },
                            error: function(data){
                                console.error(data);
                                submitButton.removeClass('processing').enable();
                                sa.toasts.toast(gettext("Something went wrong. Please try again."), sa.toasts.types.error, data);
                            },
                        });
                    });
                });
                var languageOverride = _this.urlHelpers.getParameters().language;
                if(!languageOverride) return;
                _this.i18n.setLanguage(languageOverride);
            },
            date: function(date, format){
                var options;

                // in IE11 and Edge, the options are completely ignored so the date format is ugly
                // the get around this in the future, we could add Moment.js or something like it
                // to do the conversion for us and not rely on the browsers
                // for now, we can apply a regex to fix it ONLY in en-us locales
                // the regex breaks any locale that isn't en-us, so be careful

                // this was breaking chrome as of version 110.0.5481.77
                // since we no longer support IE11 and Edge apparently has caught up with the other browser
                // it is no longer necessary
                // keeping it around in comment form JUST IN CASE it comes up later
                // if(_this.language == 'en-us'){
                //     // adjusting the date so that it works properly in all browsers per the top comment in this link:
                //     // https://skypointcloud.com/blog/a-bad-date-with-internet-explorer-11-trouble-with-new-unicode-characters-in-javascript-date-strings/
                //     date = new Date(date.toLocaleString().replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, ''));
                // }

                switch(format) {
                    case 'TIME_FORMAT':
                        options = { hour:'numeric', minute:'numeric' };
                        break;
                    case 'DATE_FORMAT':
                        options = { year: 'numeric', month: 'long', day: 'numeric' };
                        break;
                    case 'DATETIME_FORMAT':
                        options = { year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' };
                        break;
                    case 'SHORT_DATE_FORMAT':
                        options = { year: 'numeric', month: 'short', day: 'numeric' };
                        break;
                    case 'SHORT_DATETIME_FORMAT':
                    default:
                        options = { year: 'numeric', month: 'short', day: 'numeric', hour:'numeric', minute:'numeric' };
                }
                return date.toLocaleString(_this.language, options);
            }
        }
        this.sentry = {
            /*
                Available severity levels:
                Sentry.Severity: {
                    Critical: "critical"
                    Debug: "debug"
                    Error: "error"
                    Fatal: "fatal"
                    Info: "info"
                    Log: "log"
                    Warning: "warning"
                }
                // example: Sentry.Severity.Critical
            */
            initialized: false,
            setup: function(){
                if(_this.sentry.initialized) return;
                Sentry.configureScope(function(scope){
                    scope.setTag('locale', _this.language);
                });
                _this.sentry.initialized = true;
            },
            breadcrumb: function(msg, level, category, data){
                Sentry.addBreadcrumb({
                    category: category,
                    data: data,
                    message: msg,
                    level: level || Sentry.Severity.Info,
                });
            },
            setLevel: function(level){
                level = level || Sentry.Severity.Error;
                Sentry.configureScope(function(scope){
                    scope.setLevel(level);
                });
                _this.sentry.currentLevel = level;
            },
            event: function(msg, level){
                _this.sentry.setup();
                _this.sentry.setLevel(level);
                if(level == Sentry.Severity.Debug || level == Sentry.Severity.Info){
                    Sentry.captureMessage(msg);
                } else {
                    Sentry.captureException(msg);
                }
            },
        };
        this.customDropdown = {
            setup: function(){
                var namespace = '.customDropdown';
                var open = function(parent){
                    var lis = parent.find('li');
                    lis.saClick(function(event){
                        event.stopPropagation();
                        parent.trigger('selected', [$(this).attr('value')]);
                        lis.off(namespace);
                        close(parent);
                    }, namespace);
                    parent.addClass('open').offClick(function(){
                        close(parent);
                    });
                    var ul = parent.find('ul');
                    if(ul.length && !_this.utils.isInView(ul)){
                        parent.removeClass('open').addClass('open-up');
                    }
                    _this.aria.modalize.in('#' + parent.attr('id'), null, function(){
                        close(parent);
                    });
                };
                var close = function(parent){
                    parent.removeClass('open open-up');
                    _this.aria.modalize.out('#' + parent.attr('id'), null, true);
                };
                var disable = function(element){
                    if(!element.data('disabled')){
                        element.addClass('disabled');
                        element.data('disabled', true);
                        element.attr('tabindex', -1);
                    }
                };
                var enable = function(element){
                    if(element.data('disabled')){
                        element.removeClass('disabled');
                        element.attr('tabindex', 0);
                        element.data('disabled', false);
                    }
                };
                $('.custom-dropdown').each(function(){
                    var element = $(this);
                    if(!element.data('init')){
                        element.data('init', true);
                        element.saClick(function(event){
                            event.preventDefault();
                            if(!element.data('disabled')){
                                if(element.hasClass('open') || element.hasClass('open-up')){
                                    close(element);
                                } else {
                                    open(element);
                                }
                            }
                        }, namespace);
                        element.on('disable', function(){
                            disable(element);
                        });
                        element.on('enable', function(){
                            enable(element);
                        });
                    }
                });
            },
        };
        this.offClick = {
            clear: function(){
                $(document).off('click.offClick');
            },
            bind: function(element, func){
                setTimeout(function(){
                    _this.offClick.clear();
                    $(document).on('click.offClick', function(event){
                        var isSelf = _this.offClick.isSelf(element, event);
                        if(!isSelf){
                            _this.offClick.clear();
                            func();
                        }
                    });
                }, 5);
            },
            isSelf: function(element, event){
                var isSelf = element.find(event.target)[0] || event.target == element[0];
                return isSelf;
            },
        };
        this.urlHelpers = {
            getUrlSansParameters: function(){
                return window.location.origin + window.location.pathname;
            },
            getUrlWithNewParameters: function(newParams){
                return _this.urlHelpers.getUrlSansParameters() + _this.urlHelpers.getNewParameterString(newParams);
            },
            setUrlWithNewParameters: function(newParams, hash){
                var url = _this.urlHelpers.getUrlWithNewParameters(newParams);
                if(hash) url += '#' + hash;
                window.location.href = url;
            },
            updateUrlWithNewParameters: function(newParams){
                history.replaceState(null, null, _this.urlHelpers.getNewParameterString(newParams));
            },
            getParameters: function(){
                var vars = {}, hash;
                var q = document.URL.split('?')[1];
                if(q != undefined){
                    q = q.split('&');
                    for(var i = 0; i < q.length; i++){
                        hash = q[i].split('=');
                        vars[hash[0]] = hash[1];
                    }
                }
                return vars;
            },
            getPaths: function(){
                var p = window.location.pathname.split('/');
                var path = [];
                for(var i = 0; i < p.length; i++){
                    if(p[i] != ''){
                        path.push(p[i]);
                    }
                }
                return path;
            },
            changePath: function(lastToKeep, toAdd){
                var pathArray = _this.urlHelpers.getPaths();
                var newString = window.location.origin + '/';
                for(var i = 0; i < pathArray.length; i++){
                    newString += pathArray[i] + '/';
                    if (pathArray[i] == lastToKeep){
                        break;
                    }
                }
                for(var x = 0; x < toAdd.length; x++){
                    newString += toAdd[x] + '/';
                }
                return newString;
            },
            getNewParameterString: function(newParams){
                var newString = '?';
                var params = _this.urlHelpers.getParameters();
                for(var x in newParams){
                    params[x] = newParams[x];
                }
                var keys = Object.keys(params);
                for(var i = 0; i < keys.length; i++){
                    var k = keys[i];
                    var v = params[k];

                    if(v != undefined){
                        if(newString.length > 1) newString += '&';
                        newString += k + '=' + v;
                    }
                }
                if(newString == '?'){
                    return '';
                } else {
                    return newString;
                }
            },
            removeParametersFromUrl: function(parameters){
                // parameters must be an array
                var urlparts= window.location.href.split('?');
                if (urlparts.length>=2) {
                    var pars= urlparts[1].split(/[&;]/g);

                    //reverse iteration as may be destructive
                    for(var x = 0; x < parameters.length; x++){
                        for (var i= pars.length; i-- > 0;) {
                            var prefix = encodeURIComponent(parameters[x]) + '=';
                            if (pars[i].lastIndexOf(prefix) !== -1) {
                                pars.splice(i, 1);
                            }
                        }
                    }
                    if (typeof (history.replaceState) != "undefined") {
                        var newParams = pars.length > 0 ? '?' + pars.join('&') : _this.urlHelpers.getUrlSansParameters();
                        history.replaceState(null, null, newParams);
                    }
                }
            },
        };
        this.utils = {
            canCreatePdf: function(){
                // Tests to see if the browser has the capabilities to us pdfmake
            	return Object.keys && typeof Uint16Array != 'undefined';
            },
            isInView: function(el) {
                el = el[0];
                var rect = el.getBoundingClientRect();
                var elemTop = rect.top;
                var elemBottom = rect.bottom;
                // Only completely visible elements return true:
                var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
                // Partially visible elements return true:
                //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
                return isVisible;
            },
            scroll: {
                topPadding: 60,
                defaultSpeed: 1000, //ms
                to: function(element, padding, speed){
                    // jQuery Element, Padding from the top of the screen, Speed to get to the desired location
                    padding = padding || _this.utils.scroll.topPadding;
                    var top = element.offset().top - padding;
                    $('body, html').animate ({
                        scrollTop: top,
                    }, {
                        speed: speed ? speed : _this.utils.scroll.defaultSpeed,
                    });
                },
                down: function(padding, speed){
                    padding = padding || _this.utils.scroll.topPadding;
                    _this.utils.scroll.by(padding, speed);
                },
                up: function(padding, speed){
                    padding = padding || _this.utils.scroll.topPadding;
                    _this.utils.scroll.by(-padding, speed);
                },
                by: function(padding, speed){
                    var top = $('html').scrollTop() + padding;
                    $('body, html').animate ({
                        scrollTop: top
                    }, speed ? speed : _this.utils.scroll.defaultSpeed);
                },
            },
            selectAll: function(input){
                try{
                    input[0].setSelectionRange(0, 9999);
                } catch (e){
                    input.select();
                }
                return input;
            },
            players: {
                bindDragEvent: function(params){
                    var up = 'mouseup.' + params.namespace + ' touchend.' + params.namespace + ' touchcancel.' + params.namespace;
                    var move = 'mousemove.' + params.namespace + ' touchmove.' + params.namespace;
                    var exit = 'mouseleave.' + params.namespace;
                    var doc = $(document.body);
                    params.element.data('dragging', true);
                    var off = function(event){
                        doc.off(up).off(move);
                        params.element.data('dragging', false);
                        $(this).off(exit);
                        if(params.exitFunction){
                            params.exitFunction(event, params.offset);
                        }
                    };
                    doc.on(up, function(event){
                        if(params.upFunction){
                            params.upFunction(event, params.offset);
                        }
                        off();
                    })
                    .on( move , function(event){
                        if(params.element.data('dragging')){
                            params.moveFunction(event, params.offset);
                        }
                    });
                    params.container.on(exit, off);
                },
                mouseTime: function(event, container, duration, offset){
                    return _this.utils.players.percentToTime(_this.utils.players.mousePercentage(event, container, offset), duration);
                },
                mousePercentage: function(event, container, offset){
                    var width = container.width();
                    return ((_this.utils.players.mousePosition(event, container, offset) / width) * 100).clamp(0,100);
                },
                mousePosition: function(event, container, offset){
                    if(!offset) offset = 0;
                    var left = container.offset().left;
                    var eventX = _this.getPointerEvent(event).pageX;
                    return  parseInt(eventX - offset - left);
                },
                timeToPercent: function(time, duration){
                    return ((time / duration) * 100).clamp(0,100);
                },
                percentToTime: function(percent, duration){
                    return ((percent / 100) * duration).clamp(0, duration);
                },
            },
        },
        this.video = {
            getVideoById: function(id){
                for(var i = 0; i < _this.videos.length; i++){
                    if(_this.videos[i].id.indexOf(id) != -1){
                        return _this.videos[i];
                    }
                }
                return null;
            },
            pauseAll: function(){
                for(var i = 0; i < _this.videos.length; i++){
                    var video = _this.videos[i];
                    video.pause();
                }
            },
            removeByIndex: function ( index ) {
                _this.videos[index].remove();
                _this.videos.splice(index, 1);
            },
            clearRemovedPlayers: function () {
                for(var i = _this.videos.length; i > 0; i--){
                    if(!$(_this.videos[i - 1].id).length){
                        _this.video.removeByIndex(i - 1);
                    }
                }
            },
        };
        this.audio = {
            doWhenInitialized: [],
            subscribeToInit: function(func){
                _this.audio.doWhenInitialized.push(func);
            },
            initialized: function(id){
                for(var i = 0; i < _this.audio.doWhenInitialized.length; i++){
                    _this.audio.doWhenInitialized[i](id);
                }
            },
            removeByIndex: function ( index ) {
                _this.players[index].remove();
                _this.players.splice(index, 1);
            },
            clearRemovedPlayers: function () {
                for(var i = _this.players.length; i > 0; i--){
                    if(!$(_this.players[i - 1].id).length){
                        _this.audio.removeByIndex(i - 1);
                    }
                }
            },
            getPlayerById: function(id){
                for(var i = 0; i < _this.players.length; i++){
                    if(_this.players[i].id.indexOf(id) != -1){
                        return _this.players[i];
                    }
                }
                return null;
            },
            playId: function(id){
                _this.audio.pauseAll();
                if(_this.audio.getPlayerById(id)){
                    _this.audio.getPlayerById(id).play();
                }
            },
            pauseAll: function(){
                for(var i = 0; i < _this.players.length; i++){
                    _this.players[i].pause();
                }
            }
        };
        this.popups = {
            closed: function(event, element){
                // console.log(event);
                // Saved for future use
                // This fires when a popout window is closed and the parent is still open
            },
            open: function(event, element, size){
                _this.audio.pauseAll();
                _this.video.pauseAll();
                if(!sa.mobile){
                    event.preventDefault();
                    var positions = {
                        centered: {
                            x: (window.screen.width - size.x) / 2,
                            y: (window.screen.height - size.y) / 2 - 40
                        },
                        topLeft: {
                            x: 0,
                            y: 0
                        }
                    };
                    var pos = positions.topLeft;
                    var popup = window.open(element.attr('href'), '_blank', 'toolbar=no,menubar=no,titlebar=no,status=no,scrollbars=no,resizable=yes,left=' + pos.x + ',top=' + pos.y + ',width=' + size.x + ',height=' + size.y);
                    if(popup){
                        popup.onbeforeunload = function(e){
                            _this.popups.closed(e, element);
                        };
                    }
                }
            },
            sizes: {
                video: {
                    x: 768, y: 720
                },
                audio: {
                    x: 768, y: 440
                }
                // these are the original sizes, just stored temporarily 3/2020
                // for reference in case we need them
                // video: {
                //     x: 860, y: 515
                // },
                // audio: {
                //     x: 860, y: 290
                // }
            },
            default: function(){
                $('.popup-video').each(function(){
                    if($(this).data('init')) return;
                    $(this).saClick( function(event){
                        _this.popups.open(event, $(this), _this.popups.sizes.video);
                    });
                    $(this).data('init', true);
                });
                $('.popup-audio').each(function(){
                    if($(this).data('init')) return;
                    $(this).saClick( function(event){
                        _this.popups.open(event, $(this), _this.popups.sizes.audio);
                    });
                    $(this).data('init', true);
                });
            }
        };
        this.updateTimezone = function(update_url){
            // old browser that doesn't have this capability
            if(typeof Intl == 'undefined') return;
            var localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // older browser that doesn't have this capability
            if(!localTz) return;

            var sessionTz = $('html').data('tz');

            // Temporarily breaking the update if the timezone is shomehow set to 'en'
            if(sessionTz == 'en' || localTz == 'en'){
                sa.sentry.breadcrumb('Session TZ: ' + sessionTz + 'Local Timezone: ' + localTz, Sentry.Severity.Error, 'timezone update');
                sa.sentry.event("Something went wrong retrieving the user's timezone.", Sentry.Severity.Error);
                return;
            }

            // we already have the correct tz stored
            if(sessionTz == localTz) return;
            $('[data-update-with-timezone]').each(function(){
                var data = $(this).data();
                var adjustedDate = _this.i18n.date(new Date(data.updateWithTimezone * 1000), data.dateFormat)
                $(this).text(adjustedDate);
            });
            // someone forgot to include a url for this function
            if(!update_url){
                console.error('Please provide a url in order to use update this timezone');
                return;
            }
            $.get(update_url, {
                'tz': localTz,
            });
        };
        this.checkBrowser = function(){
            var data = document.documentElement.dataset;
            _this.language = data.cookieLang || data.browserLang;
            var ua = window.navigator.userAgent;
            if(/android|Android|mobi|blackberry|Mobi|phone|Phone/i.test(ua)){
                _this.body.className += ' mobile';
                _this.mobile = true;
            } else {
                _this.body.className += ' desktop';
            }

            if(ua.match(/Trident/)){
                _this.ie = true;
                _this.body.className += ' ie';
            } else if (ua.match(/Safari/) && !ua.match(/Chrom/)){
                _this.body.className += " safari";
                _this.safari = true;
            } else if (ua.match(/Edge|edge/)){
                _this.body.className += ' edge';
                _this.edge = true;
            }

            var audio = document.createElement('audio');
            var noHTML5 = !audio.canPlayType || !audio.canPlayType('audio/mpeg;');
            if(noHTML5){
                _this.html5 = false;
                _this.body.className += ' noHTML5';
            }
        };
        this.hasScrollbar = function(element){
            var height = -1;
            element.children().each(function(){
                height += $(this).outerHeight(true);
            });
            return element.height() < height;
        };
        this.checkScrollbars = function(){
            $('.scrolls.pad').each(function(){
                var hasClass = $(this).hasClass('padded');
                var hasScrollbar = _this.hasScrollbar($(this));
                if(hasClass && !hasScrollbar ){
                    $(this).removeClass('padded');
                } else if (!hasClass && hasScrollbar){
                    $(this).addClass('padded');
                }
            });
        };
        this.viewportHeight = function (){
            return window.innerHeight || $(window).height();
        };
        this.dropdownValue = function(elementId){
            return $('#' + elementId + ' select option:selected').prop('value');
        };
        this.validNumber = function(num){
            return typeof num === 'number' && num !== undefined && num !== null && !isNaN(num) && num != Infinity;
        };
        this.remainingTime = function(s){
            var seconds = Math.ceil(s);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            if(hours > 0){
                var h = ngettext('Est. %s hour remaining', 'Est. %s hours remaining', hours);
                return interpolate(h,[hours]);
            } else if (minutes > 0){
                var m = ngettext('Est. %s min. remaining', 'Est. %s min. remaining', minutes);
                return interpolate(m,[minutes]);
            } else {
                var sec = ngettext('Est. %s sec. remaining', 'Est. %s sec. remaining', seconds);
                return interpolate(sec,[seconds]);
            }
        };
        this.toHHMMSS = function(s, removeLeadingZeros){
            var leadingZeroes = removeLeadingZeros ? '' : '0';
            var sec_num = parseInt(s, 10);
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);
            if (minutes < 10) {minutes = leadingZeroes + minutes;}
            if (seconds < 10) {seconds = leadingZeroes + seconds;}

            if (hours < 10 && hours > 0) {
                hours = leadingZeroes + hours + ':';
            } else if (hours >= 10){
                hours = hours + ':';
            } else{
                hours = '';
            }
            return hours + minutes + ':' + seconds;
        };
        this.toDecimals = function (num,places){
            var divider = places > 0 ? Math.pow(10,places) : 1;
            return Math.round((num + 0.00001) * divider) / divider;
        };
        this.setEndOfContenteditable = function (contentEditableElement) {
            contentEditableElement = contentEditableElement[0];
            var range,selection;
            if(document.createRange) {//Firefox, Chrome, Opera, Safari, IE 9+
                range = document.createRange();//Create a range (a range is a like the selection but invisible)
                range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
                range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                selection = window.getSelection();//get the selection object (allows you to change selection)
                selection.removeAllRanges();//remove any selections already made
                selection.addRange(range);//make the range you have just created the visible selection
            } else if(document.selection) { //IE 8 and lower
                range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
                range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
                range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                range.select();//Select the range (make it the visible selection
            }
        };
        this.toasts = {
            appendTo: '.toasts-container',
            types:{
                alert: 'alert',
                error: 'alert',
                success: 'success',
                warning: 'warning',
                info: 'info',
            },
            assemble: function(msg, type, responseError, jsError){
                if(!type) type = _this.toasts.types.info;
                var errorData = '';
                if(type == _this.toasts.types.error){
                    if(responseError){
                        _this.sentry.breadcrumb(msg, Sentry.Severity.Error, undefined, responseError);
                        if(responseError.responseText){
                            if (typeof responseError.responseText == 'string'){
                                errorData += '<span class="error-messages">' + responseError.responseText + '</span>';
                            } else {
                                var parsed = JSON.parse(responseError.responseText);
                                if(parsed){
                                    var errors = parsed.errors;
                                    if(errors && Object.keys(errors).length){
                                        var err;
                                        var date = new Date();
                                        date = '\n  -- ' + date.toString();
                                        for (key in errors) {
                                            err = _this.toasts.formatKey(key) + errors[key];
                                            _this.errors.push(err + date);
                                            console.log(err + date);
                                            errorData += '<span class="error-messages">' + err + '</span>';
                                        }
                                    }
                                }
                            }
                        }
                    } else if (jsError){
                        _this.sentry.breadcrumb(msg, Sentry.Severity.Error, undefined, jsError);
                        date = '\n  -- ' + new Date().toString();
                        errorData += '<span class="error-messages">' + jsError + date + '</span>';
                    }
                }
                return '<div class="toast ' + type + '" ><span class="msg">' + msg + '</span>' + errorData + '</div>';
            },
            formatKey: function(key){
                var keyArray = key.split(/(?=[A-Z])/);
                if(keyArray.length <= 1 && key.indexOf('_') != -1){
                    keyArray = key.split(/_/);
                }
                var newKey = '';
                for(var i = 0; i < keyArray.length; i++){
                    var word = keyArray[i];
                    word = word.charAt(0).toUpperCase() + word.slice(1);
                    newKey += word;
                    if(i == keyArray.length - 1) newKey += ':';
                    newKey += ' ';
                }
                return newKey;
            },
            toast: function(msg, type, responseError, jsError){
                var duplicate = false;
                $('.toast').each(function(){
                    if($(this).text() == msg) duplicate = true;
                });
                if(!duplicate){
                    var toast = $(_this.toasts.assemble(msg, type, responseError, jsError)).appendTo(_this.toasts.appendTo);
                    toast.on('click', function(){
                        toast.remove();
                        if(_this.aria.outline.lastBlur){
                            _this.aria.outline.lastBlur.focus();
                        }
                    });
                    toast.one(_this.animationPrefixes, function() {
                        toast.remove();
                    });
                }
            },
        };
        this.alertModal = function(params){
            var neutral = $(params.modal + ' .neutral').not(params.modal + ' .modal-sub-container .neutral');
            var negative = $(params.modal + ' .negative').not(params.modal + ' .modal-sub-container .negative');
            var positive = $(params.modal + ' .positive').not(params.modal + ' .modal-sub-container .positive');
            var all = neutral.add(negative).add(positive);

            var close = function(){
                $(params.modal).removeClass('active').trigger('closed');
                sa.aria.modalize.out(params.modal, null, true);
                all.off();
            };

            if(params.off_click || params.default_close){
                $(params.modal + ' .modal-background').saClick(() => {
                    close();
                });
            }
            if(params.default_close){
                $(params.modal + ' .modal-close-button').saClick(() => {
                    close();
                });
            }

            var close_button = params.default_close ? '.modal-close-button' : '.neutral';
            sa.aria.modalize.in(params.modal, params.modal + ' ' + close_button, close);
            $(params.modal).addClass('init active').on('close', close);
            // parameter that keeps "negative" and "neutral" buttons from closing the modal
            if(params.confirmation_close || params.confirmation_close == undefined){
                all.saClick( function(){
                    setTimeout(function(){
                        close();
                    }, 0);
                });
            } else {
                neutral.saClick( function(){
                    setTimeout(function(){
                        close();
                    }, 0);
                });
            }
            if(params.neutral){
                neutral.saClick(params.neutral);
            }
            if(params.positive){
                positive.saClick(params.positive);
            }
            if(params.negative){
                negative.saClick(params.negative);
            }
            if(params.all){
                all.saClick(params.all);
            }
        };
        this.aria = {
            outline:{
                lastOutlined: null,
                lastBlur: null,
                lastKeypress: $.now(),
                lastKeypressThreshold: 1000, // amount of ms required since the last keypress to trigger the outline
                focusFunctions: [],
                focused: function(el){
                    var funcArray = _this.aria.outline.focusFunctions;
                    for(var i = 0; i < funcArray.length; i++){
                        if(funcArray[i][0] == el){
                            funcArray[i][1]();
                        }
                    }
                },
                setup: function(){
                    if(!sa.mobile){
                        var keysToCheck = [
                            _this.keycodes.tab,
                            _this.keycodes.enter,
                            _this.keycodes.esc,
                            _this.keycodes.space,
                            _this.keycodes.arrowLeft,
                            _this.keycodes.arrowUp,
                            _this.keycodes.arrowRight,
                            _this.keycodes.arrowDown
                        ];
                        $(document).on('keydown.outline', function(event){
                            for(var i = 0; i < keysToCheck.length; i++){
                                if(event.keyCode == keysToCheck[i]){
                                    _this.aria.outline.lastKeypress = $.now();
                                }
                            }
                        });
                        $(':focusable, :focusable:hidden').on('focus.outline mousedown.outline blur.outline', function(event){
                            $('.outline').removeClass('outline');
                            if(event.type == 'mousedown'){
                                $(this).data('mousedown',true);
                            } else if (event.type == 'blur'){
                                $(this).data('mousedown',false);
                                _this.aria.outline.lastBlur = $(this);
                            } else if (event.type == 'focus' && !$(this).data('mousedown')){
                                if($.now() - _this.aria.outline.lastKeypress <= _this.aria.outline.lastKeypressThreshold && !$(this).hasClass('never_focus')){
                                    var element = $(this);
                                    if(element.parent().hasClass('pseudo-input') || element.parent().hasClass('dropdown')){
                                        element = $(this).parent();
                                    }
                                    element.addClass('outline');
                                    _this.aria.outline.lastOutlined = element;
                                    _this.aria.outline.focused(element);
                                }
                            }
                        }).on('keydown.outline', function(event){
                            for(var i = 0; i < keysToCheck.length; i++){
                                if(event.keyCode == keysToCheck[i]){
                                    _this.aria.outline.lastKeypress = $.now();
                                }
                            }
                        });
                    }
                },
                update: function(){
                    setTimeout(function(){
                        _this.aria.outline.setup();
                    }, 1);
                }
            },
            modalize: {
                array: [],
                getModalElements: function(id, closeButton){
                    var list = id + ':focusable, ' + id + ' *:focusable';
                    if(closeButton){
                        list += ', ' + closeButton;
                    }
                    return $(list).not('.never_focus');
                },
                getAllOtherElements: function(id){
                    return $(':focusable').not(id + ', ' + id + ' *, ' + '.never_focus');
                },
                hideElements: function(id, button){
                    _this.aria.modalize.getModalElements(id).not(button).attr('tabindex', '-2');
                },
                showElements: function(id){
                    $(id + ' *[tabindex=-2]').attr('tabindex', '0');
                },
                in: function(id, closeButton, escFunc, focusElement){
                    setTimeout(function(){
                        var tempArray = [];
                        _this.aria.modalize.getAllOtherElements(id).each(function(){
                            tempArray.push({element: $(this), tabindex: $(this).attr('tabindex')});
                            $(this).attr('tabindex', '-1');
                        });
                        var modal = _this.aria.modalize.getModalElements(id, closeButton);
                        if(escFunc){
                            modal.keysEvent([_this.keycodes.esc], 'keydown.modalize', function(){
                                $(this).off('keydown.modalize');
                                escFunc();
                            });
                        }
                        modal.attr('tabindex', '0');
                        _this.aria.modalize.setTabs(modal);
                        if(focusElement){
                            focusElement.focus();
                        } else if(closeButton && closeButton == modal[0] && modal[1]){
                            modal[1].focus();
                        } else if (modal[0]) {
                            modal[0].focus();
                        }
                        _this.aria.modalize.array = tempArray;
                        _this.aria.outline.update();
                    }, 0);
                },
                out: function(id, closeButton, noFocus){
                    for(var i = 0; i < _this.aria.modalize.array.length; i++){
                        var index = _this.aria.modalize.array[i];
                        if(index.tabindex){
                            index.element.attr('tabindex', index.tabindex);
                        } else {
                            index.element.removeAttr("tabindex");
                        }
                    }
                    var elements = _this.aria.modalize.getModalElements(id, closeButton);
                    elements.off('keydown.modalize');
                    _this.aria.modalize.unsetTabs(elements);
                    _this.aria.modalize.getModalElements(id).attr('tabindex', '-1');
                    if(!noFocus) elements.first().focus();
                    _this.aria.outline.update();
                },
                unsetTabs: function(elements){
                    elements.first().off('keydown.modalize');
                    elements.last().off('keydown.modalize');
                },
                setTabs: function(elements){
                    var firstInput = elements.first();
                    var lastInput = elements.last();
                    /*set focus on first input*/
                    /*redirect last tab to first input*/
                    lastInput.on('keydown.modalize', function (e) {
                       if ((e.keyCode === _this.keycodes.tab && !e.shiftKey)) {
                           e.preventDefault();
                           firstInput.focus();
                       }
                    });

                    /*redirect first shift+tab to last input*/
                    firstInput.on('keydown.modalize', function (e) {
                        if ((e.keyCode === _this.keycodes.tab && e.shiftKey)) {
                            e.preventDefault();
                            lastInput.focus();
                        }
                    });
                }
            }
        };
        this.webcasts = {
            live: [],
            intervalSet: false,
            recurringInterval: null,
            updateCallbacks: [],
            interval: 60, // update interval in seconds
            update: function(){
                for(var i = 0; i < _this.webcasts.updateCallbacks.length; i++){
                    _this.webcasts.updateCallbacks[i]();
                }
                _this.webcasts.queueWebcastCheck();
            },
            updateIntervalSpeed: function(newInterval){
                if(typeof newInterval == 'number'){
                    _this.webcasts.interval = newInterval;
                }
            },
            subscribeToWebcastCheck: function (updateFunction){
                _this.sentry.breadcrumb('Subscribe to webcast in progress check', Sentry.Severity.Info, 'webcasts.in_progress');
                _this.webcasts.updateCallbacks.push(updateFunction);
                _this.webcasts.initiateWebcastCheck();
            },
            initiateWebcastCheck: function(){
                if(_this.webcasts.intervalSet) {
                    return;
                } else {
                    _this.webcasts.intervalSet = true;
                    _this.webcasts.queueWebcastCheck();
                }
            },
            queueWebcastCheck: function(){
                setTimeout(_this.webcasts.getWebcastsInProgress, _this.webcasts.interval * 1000);
            },
            getWebcastsInProgress: function(){
                $.ajax({
                    url: '/webcasts_in_progress.json',
                    success: function(data){
                        _this.webcasts.live = data;
                        _this.webcasts.update();
                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        _this.webcasts.queueWebcastCheck();
                    },
                    contentType: "application/json"
                });
            },
            getWebcastsInProgressBySource: function (source){
                for(var i = 0; i < _this.webcasts.live.length; i++){
                    var wc = _this.webcasts.live[i];
                    if(wc.broadcaster_id == source){
                        return {
                            total_count: wc.total_count,
                            webcast_id: wc.webcast_id,
                            concurrent_count: wc.concurrent_count,
                        };
                    }
                }
                return false;
            },
            setupNotifications: function(){
                $('.broadcaster-online-notification').find('a, .dismiss').saClick( function(){
                    $(this).parent().removeClass('show');
                });
            }
        };
        this.setupNumberInput = function(){
            $('input[type=number]:not(.init)').each(function(){
                var el = $(this);
                el.addClass('init');
                var events = 'scroll.numbers touchmove.numbers mousewheel.numbers wheel.numbers';
                var shiftValue = function(up){
                    var pVal = parseInt(el.val(), 10);
                    if(!pVal) pVal = 0;
                    var val = up ? pVal + 1 : pVal - 1;
                    var min = el.attr('min') * 1;
                    var max = el.attr('max') * 1;
                    if (val < min){
                        val = min;
                    } else if (val > max){
                        val = max;
                    }
                    if((!up && (!min || val >= min)) || (up && (!max || val <= max))){
                        el.val(val);
                    }
                };
                el.on('mouseenter', function(){
                    $(document).on(events, function(event){
                        event.preventDefault();
                        var e = event.originalEvent;
                        var value = false;
                        if(e.deltaY != undefined){
                            value = e.deltaY;
                        } else if (event.originalEvent.wheelDelta){
                            value = event.originalEvent.wheelDelta;
                        }
                        if(value){
                            var up = value < 0;
                            shiftValue(up);
                            el.trigger('scrolled');
                        }
                    });
                }).on('mouseleave', function(){
                    $(document).off(events);
                }).keysEvent([sa.keycodes.arrowUp, sa.keycodes.arrowDown], 'keydown.numbers', function(event){
                    shiftValue(event.keyCode == sa.keycodes.arrowUp);
                    el.trigger('arrowpressed');
                });
            });
            $('.time-input:not(.init)').each(function(){
                var parent = $(this);
                parent.addClass('init');
                var sec = parent.find('.seconds');
                var min = parent.find('.minutes');
                var hours = parent.find('.hours');
                var inputs = parent.find('input');

                var originalMin = parent.data('min');
                var originalMax = parent.data('max');
                var originalValue = parent.data('val');

                var addValues = function(){
                    return (sec.val() * 1) + (min.val() * 60) + (hours.val() * 3600);
                };
                var checkHourVisibility = function(){
                    if(parent.data('max') < 3600){
                        if (!parent.hasClass('no-hours')) parent.addClass('no-hours');
                    } else if (parent.hasClass('no-hours')){
                        parent.removeClass('no-hours');
                    }
                    return !parent.hasClass('no-hours');
                }
                var setTime = function(value){
                    parent.data('val', value);
                    var new_hours = Math.floor(value / 3600);
                    hours.val(new_hours < 10 ? '0' + new_hours : new_hours);
                    value -= new_hours * 3600;
                    var new_minutes = Math.floor(value / 60);
                    min.val(new_minutes < 10 ? '0' + new_minutes : new_minutes);
                    value -= new_minutes * 60;
                    value = Math.floor(value);
                    sec.val(value < 10 ? '0' + value : value);
                };
                var update = function(){
                    checkHourVisibility();
                    var val = addValues().clamp(parent.data('min'), parent.data('max'));
                    setTime(val);
                };

                var updateWithTrigger = function(){
                    update();
                    parent.trigger('updated');
                };

                var inputEvents = 'input.timeEvent scrolled.timeEvent arrowpressed.timeEvent';

                sec.on(inputEvents, function(){
                    var val = parseInt($(this).val());
                    var minVal = parseInt(min.val());
                    if(val < 0){
                        sec.val(59);
                        min.val(minVal - 1);
                    } else if (val > 59){
                        sec.val(0);
                        min.val(minVal + 1);
                    }
                    updateWithTrigger();
                });
                min.on(inputEvents, function(){
                    var val = parseInt($(this).val());
                    var hourVal = parseInt(hours.val());
                    if(val < 0){
                        min.val(59);
                        hours.val(hourVal - 1);
                    } else if (val > 59){
                        min.val(0);
                        hours.val(hourVal + 1);
                    }
                    updateWithTrigger();
                });
                hours.on(inputEvents, updateWithTrigger);

                inputs.add(parent).on('update.timeEvent', update);
                inputs.on('click.selectAll', function(){
                    _this.utils.selectAll($(this));
                });

                parent.on('reset.timeEvent', function(){
                    parent.data({
                        min: originalMin,
                        max: originalMax,
                        val: originalValue,
                    }).removeClass('disabled').trigger('update');
                    inputs.prop('disabled', false);
                });
                parent.on('set.timeEvent', function(event, value){
                    setTime(value);
                    checkHourVisibility();
                });

                min.keysEvent([sa.keycodes.colon, sa.keycodes.arrowRight], 'keydown.timeEvent', function(){
                    sec.focus();
                });
                min.keysEvent([sa.keycodes.arrowLeft], 'keydown.timeEvent', function(){
                    if(checkHourVisibility()){
                        hours.focus();
                    }
                });
                hours.keysEvent([sa.keycodes.colon, sa.keycodes.arrowRight], 'keydown.timeEvent', function(){
                    min.focus();
                });
                sec.keysEvent([sa.keycodes.arrowLeft], 'keydown.timeEvent', function(){
                    min.focus();
                });
            });
        };
        this.setupSelectAll = function(){
            $('.select-all').each(function(){
                $(this).on('click', function(){
                    _this.utils.selectAll($(this));
                });
            });
        };
        this.setupCopyText = function(){
            $('.copy-code-button').each(function(){
                $(this).saClick(sa.copyText);
            })
        };
        this.copyText = function (e){
            var c = e.target.dataset.copytarget,
            input = (c ? document.querySelector(c) : null);

            if (input && input.select) {
                _this.utils.selectAll(input);
                try {
                    clearTimeout(_this.copyTextTimeout);
                    document.execCommand('copy');
                    input.blur();
                    $(e.target).attr('aria-label', 'Link Copied');
                    $(e.target).addClass('success');
                    _this.copyTextTimeout = setTimeout(function(){
                        $(e.target).removeClass('success');
                    }, 3000);
                    console.info('Copied successfully.');
                    return true;
                }
                catch (err) {
                    console.error(err);
                    alert(gettext('An error occurred; please press Ctrl/Cmd+C to copy.'));
                    return false;
                }
            }
            return false;
        };
        this.getPointerEvent = function(event){
            // if originalEvent.touches exists, we need to use it instead of event.
            // this is primarily to allow the use of event.pageX on android.
            var hasTouchlist = event.originalEvent.touches && event.originalEvent.touches.length
            return hasTouchlist ? event.originalEvent.touches[0] : event;
        };
        this.search = {
            callbacks: [],
            keyupCallbacks: [],
            submit: function(el){
                for(var i = 0; i < _this.search.callbacks.length; i++){
                    var isFocus = _this.search.callbacks[i][0].siblings().is(':focus') || _this.search.callbacks[i][0].is(':focus');
                    if(isFocus && _this.search.validate(el)){
                        _this.search.callbacks[i][0].addClass('searching');
                        _this.search.callbacks[i][1]();
                    }
                }
            },
            keyup: function(e){
                if($('.search-input').is(":focus")){
                    var enter = e.which == _this.keycodes.enter;
                    if(_this.search.validate($('.search-input:focus'))){
                        if(!$('.search-input:focus').parent().hasClass('filtering')){
                            $('.search-input:focus').parent().addClass('filtering');
                        }
                        if(enter){
                            _this.search.submit($('.search-input:focus'));
                        }
                    } else {
                        $('.search-input:focus').parent().removeClass('filtering');
                        if(enter && sa.sermonList){
                            _this.search.submit($('.search-input:focus'));
                        }
                    }
                }
            },
            subscribe: function(element, searchSubscription){
                _this.search.callbacks.push([element, searchSubscription]);
            },
            validate: function(el){
                var val = el.val();
                return val && val != '';
            },
            setup: function(){
                $('.search-input').on('focus', function(){
                    $(this).addClass('focus');
                });
                $('.search-input').on('focusout', function(){
                    $(this).removeClass('focus');
                });
                $('.search-close-button').saClick( function(){
                    $(this).parent().removeClass('filtering').find('.search-input').val('');
                    if(sa.sermonList){
                        sa.sermonList.query = '';
                        sa.sermonList.search();
                    }
                });
                $('.search-button').saClick(function(){
                    var input = $(this).parent().find('.search-input');
                    if(_this.search.validate(input)){
                        _this.search.submit(input);
                    } else {
                        input.focus();
                    }
                });
                $('.search-input').on('keyup', function(e) {
                    _this.search.keyup(e);
                });
            }
        };
        this.playerOverlays = {
            setupToggles: function(){
                $('[data-toggle-show]').each(function(){
                    $(this).saClick(function(){
                        $('#' + $(this).data('toggle-show')).toggleClass('show');
                    });
                });
            },
            setupWebcastNotifications: function(){
                $('[data-online-notification-source]').each(function(){
                    var source = $(this).data('online-notification-source');
                    var notification = $(this);
                    sa.webcasts.subscribeToWebcastCheck( function(){
                        var online = sa.webcasts.getWebcastsInProgressBySource(source);
                        var visible = notification.hasClass('show');
                        if(online && !visible){
                            notification.addClass('show');
                        } else if (!online && visible) {
                            notification.removeClass('show');
                        }
                    });
                });
            },
        };
        this.qrCodes = {
            setup: function(){
                $('[data-generate-qr-id]').each(function(){
                    $(this).saClick(function(){
                        var data = $(this).data();
                        if(data.generated) return;
                        var id = data.generateQrId;
                        var url = data.generateQrUrl;
                        var qrcode = new QRCode(id, {
                            text: url,
                            width: 132,
                            height: 132,
                            correctLevel : QRCode.CorrectLevel.L,
                        });
                        $(this).data('generated', true);
                    });
                });
            },
        };
        this.dynamicContent = function(){
            _this.setupNumberInput();
            _this.aria.outline.setup();
            _this.accordion.setup();
            _this.checkScrollbars();
            _this.customDropdown.setup();
            _this.popups.default();
            if(sa.site){
                sa.site.tooltips.setup();
            }
        };
        this.countUp = {
            defaultDuration: 250,
            start: function(parent, duration){
                parent.addClass('updated');
                parent.data({
                    'value': _this.countUp.parseInt(parent.data('value')),
                    'startingValue': _this.countUp.parseInt(parent.data('startingValue')),
                });
                _this.countUp.iterate({
                    parent: parent,
                    duration: duration,
                    startingTimestamp: 0,
                    currentTimestamp: 0,
                });
            },
            iterate: function(countUpSettings){
                // countUpSettings should include
                // { parent, startingTimestamp, currentTimestamp }
                var duration = countUpSettings.duration || _this.countUp.defaultDuration;
                var percentage = (countUpSettings.currentTimestamp - countUpSettings.startingTimestamp) / duration;
                var newValue = countUpSettings.parent.data('value');
                var startingValue = countUpSettings.parent.data('startingValue');
                var countedUpValue = startingValue + Math.floor((newValue - startingValue) * percentage);
                countedUpValue = countedUpValue.clamp(startingValue, newValue);
                countUpSettings.parent.find('span').text(sa.intComma(countedUpValue));

                var complete = percentage >= 1;
                if(!complete){
                    _this.animationFrame((timestamp) => {
                        if(!countUpSettings.startingTimestamp) countUpSettings.startingTimestamp = timestamp;
                        _this.countUp.iterate({
                            parent: countUpSettings.parent,
                            duration: countUpSettings.duration,
                            startingTimestamp: countUpSettings.startingTimestamp,
                            currentTimestamp: timestamp,
                        });
                    });
                } else {
                    countUpSettings.parent.removeClass('updated');
                }
            },
            startWithNewValues: function(options){
                // options should include
                // { parent, value }
                _this.countUp.setValues({
                    parent: options.parent,
                    value: options.value,
                });
                _this.countUp.start(options.parent, options.duration);
            },
            setValues: function(options){
                // options should include
                // { parent, value }
                var startingValue = options.parent.data('value');
                options.parent.data({
                    'value': options.value,
                    'startingValue': startingValue,
                });
            },
            parseInt: function(stringValue){
                stringValue = stringValue.toString().replace(/[^\d]/g, '')
                return parseInt(stringValue);
            },
            valueWithSeparators: function(value){
                if(value < 1000) return value;
                return value.toLocaleString(sa.language);
            },
        };
        this.intComma = function(value){
            value = parseInt(value)
            if(value < 1000) return value;
            return value.toLocaleString(sa.language);
        };
        _this.init();
    };
}());

var sa = new sa();
